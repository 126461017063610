ul.filters {
  margin: 2rem 0;
  padding: 0;
  list-style: none;
  li {
    display: inline-block;
    margin: 0 0.5rem 0.5rem 0;
    a {
      display: inline-block;
      font-size: 0.8em;
      font-weight: 600;
      text-transform: uppercase;
      line-break: strict; /* Fix Safari Big Sur line break issue */
      padding: 0.4rem 1rem;
      background-color: $color-background-secondary;
      color: $color-text-primary;
      border-radius: 0.6rem;
      .bi {
        margin-left: 0.4em;
        margin-right: -0.3em;
      }
      &:hover {
        color: white;
        background-color: $color-primary;
        @include animate-transition((color, background-color));
      }
    }
    &.selected {
      a {
        color: white;
        background-color: $color-primary;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    li {
      margin: 0 0.8rem 0.8rem 0;
      a {
        font-size: 1em;
        font-weight: 500;
        padding: 0.6rem 1.2rem;
      }
    }
  }
}

.photos-container {
  margin-bottom: 1.5rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.product-photos {
  $space: 6px;
  .main-photo {
    width: 100%;
    background-color: $color-placeholder;
  }
  .side-photos {
    margin-top: $space;
    display: flex;
    .side-container {
      flex-grow: 1;
      margin-right: $space;
      &:last-child {
        margin-right: 0;
      }
    }
    .photo {
      display: block;
      padding-bottom: 66%;
      background-size: cover;
      background-position: center;
      background-color: $color-placeholder;
    }
  }
}

table.product-details {
  td {
    padding-right: 1.0rem;
    &:last-child {
      padding-right: 0;
      font-weight: 500;
    }
  }
  @include media-breakpoint-up(sm) {
    td {
      padding-right: 2.0rem;
    }
  }
}

.contact-us {
  margin-top: 1.6rem;
}

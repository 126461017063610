// Custom fonts
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap");

// Global variables and mixins
@import './variables-mixins';

// Import and customize bootstrap
@import './custom-bootstrap';

// Global website style
@import 'website';

// Page-specific styles
@import '../views/home/home';
@import '../views/products/products';
@import '../views/company/company';
@import '../views/terms/terms';

// Generic components

a {
  font-weight: 500;
  &:hover {
    text-decoration: none;
  }
  &.email {
    font-weight: inherit;
  }
}

h1 {
  @include font-title-1;
  margin-top: 3.6rem;
  margin-bottom: 1.2rem;
}

h2 {
  @include font-title-2;
  margin-top: 1.6rem;
  margin-bottom: 0.5rem;
}

.container-h1 {
  align-items: baseline;
  margin-bottom: 1.2rem;
  h1 {
    margin-bottom: 0;
  }
  a {
    font-size: 1.4rem;
  }
}

.card-container {
  margin-bottom: -$gutter;
  .card-item {
    margin-bottom: $gutter;
  }
  .card-content {
    height: 100%;
    background-color: $color-background-item;
    box-shadow: 0 2px 12px rgba($color: #000000, $alpha: 0.15);
  }
  a.card-content {
    display: block;
    @include animate-transition((box-shadow, transform));
    &:hover {
      box-shadow: 0 2px 24px rgba($color: #000000, $alpha: 0.35);
      transform: scale(1.02);
    }
  }
}

.product-card {
  .image {
    padding-bottom: 60%;
    background-size: cover;
    background-position: center;
    background-color: $color-placeholder;
  }
  .title {
    @include font-card-title;
    padding: 1rem;
  }
}

.list-placeholder {
  @include font-list-placeholder;
  text-align: center;
  margin: 5rem 0;
}

.breadcrumb {
  background-color: $color-background-secondary;
  border-radius: 0.6rem;
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.8rem;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0.8rem;
  }
}

// General layout

body {
  @include font-body;
  background-color: $color-background-primary;
  > header, > main, > footer {
    margin: 0 auto;
    @media (min-width: 1921px) {
      max-width: 1400px;
    }
  }
}

body > header {
  padding: 3rem 0 1.7rem 0;
  background-color: $color-background-secondary;
  background-image: url('/img/header-bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  .header-logo {
    display: inline-block;
    font-size: 1.9rem;
    padding: 0.65em 0.8em;
    background: rgba($color: #000000, $alpha: 0.5);
    backdrop-filter: blur(10px);
    border: 0.125rem solid white;
    color: white;
    text-shadow: 0 2px 12px rgba($color: #000000, $alpha: 0.5);
    .title {
      font-size: 1em;
      font-weight: 800;
      text-transform: uppercase;
      line-height: 1;
    }
    .subtitle {
      margin-top: 0.4em;
      font-size: 0.65em;
      line-height: 1;
    }
  }
  .header-nav {
    margin-top: 2rem;
    ul {
      margin: 0;
      padding: 0;
      display: inline-block;
      list-style: none;
      li {
        display: inline-block;
        margin: 0.7rem 2rem;
        a {
          font-size: 1.5rem;
          font-weight: 600;
          text-transform: uppercase;
          color: white;
          text-shadow: 0 2px 10px #000000;
          line-height: 1;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(sm) {
    padding: 6rem 0 1.5rem 0;
    .header-logo {
      font-size: 2.1rem;
      padding: 0.65em 1.2em;
    }
    .header-nav {
      margin-top: 4.5rem;
    }
  }
  @include media-breakpoint-up(md) {
    padding: 7rem 0 1.5rem 0;
    .header-logo {
      font-size: 2.3rem;
    }
    .header-nav {
      margin-top: 5.5rem;
    }
  }
}

body > main {
  padding: 2rem 0;
  @include media-breakpoint-up(sm) {
    padding: 3rem 0;
  }
}

body > footer {
  @include font-note;
  padding: 2rem 0;
  text-align: center;
  background-color: $color-background-secondary;
  nav {
    margin-top: 1rem;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: inline-block;
        margin: 0 0.5rem;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 3.2rem 0;
    nav {
      margin-top: 0;
      ul li {
        margin: 0 0 0 1rem;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.container-root {
  @extend .container-fluid;
  max-width: 1400px; // Avoid extra-large layout on big screens
  padding-left: 15px;
  padding-right: 15px;
  @include media-breakpoint-up(lg) {
    padding-left: 80px;
    padding-right: 80px;
  }
}

// Contact

.contact-container {
  margin-bottom: - 2 * $gutter;
  > div {
    margin-bottom: 2 * $gutter;
  }
  .contact-item {
    .row {
      @include font-note;
      margin-top: 1.5rem;
      margin-bottom: -$gutter;
      > div {
        margin-bottom: $gutter;
      }
    }
    .image {
      margin-bottom: 1.5rem;
      padding-bottom: 28%;
      background-size: cover;
      background-position: center;
      background-color: $color-placeholder;
    }
    .infos {
      line-height: 1.2;
      .title {
        margin-top: 0.8rem;
        font-weight: 600;
        &:first-child {
          margin-top: 0;
        }
      }
      table {
        td {
          padding-right: 0.8rem;
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
    .map {
      min-height: 280px;
      .mapkit {
        height: 100%;
        background-color: $color-placeholder;
        box-shadow: 0 2px 12px rgba($color: #000000, $alpha: 0.15);
      }
    }
  }
  .contact-shop, .contact-workshop {
    margin-left: 0;
    margin-right: 0;
  }
  h2 {
    margin-top: 0;
  }
  @include media-breakpoint-up(lg) {
    .contact-shop {
      margin-right: $gutter / 2;
    }
    .contact-workshop {
      margin-left: $gutter / 2;
    }
  }
}

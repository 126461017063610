// Bootstrap overrides
$primary: $color-primary;
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 1.2rem;
$body-color: $color-text-primary;
$grid-gutter-width: $gutter;
$breadcrumb-divider: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEzIiB2aWV3Qm94PSIwIDAgOCAxMyIgd2lkdGg9IjgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTM2NC4zNDgyMzEgMzljLjM1NzU0MiAwIC42NjI5NDItLjEyMjQxMDUuOTE2MjAxLS4zNjcyMzE2bDUuMjIxNjAyLTUuMDIzNzI4OWMuMTczODA1LS4xNzYyNzExLjMwMTY3NS0uMzUxMzE4Mi4zODM2MTItLjUyNTE0MTJzLjEyNTM4OC0uMzY4NDU1Ny4xMzAzNTQtLjU4Mzg5ODNjMC0uNDAxNTA2Ni0uMTcxMzIyLS43Njg3MzgyLS41MTM5NjYtMS4xMDE2OTQ5bC01LjIyMTYwMi01LjAzMTA3MzVjLS4xMjkxMTItLjEyMjQxMDUtLjI2OTM5OC0uMjE0MjE4NC0uNDIwODU3LS4yNzU0MjM3LS4xNTE0NTgtLjA2MTIwNTMtLjMxNjU3My0uMDkxODA3OS0uNDk1MzQ0LS4wOTE4MDc5LS4yNDgyOTMgMC0uNDc0MjQuMDU5OTgxMi0uNjc3ODQuMTc5OTQzNXMtLjM2NjIzMi4yNzkwOTYtLjQ4Nzg5Ni40Nzc0MDExYy0uMTIxNjYzLjE5ODMwNTEtLjE4MjQ5NS40MTQ5NzE4LS4xODI0OTUuNjUgMCAuMzcyMTI4MS4xNDY0OTMuNjk3NzQwMS40Mzk0NzkuOTc2ODM2Mmw0LjQzNDEzOSA0LjIyNjUxMy00LjQzNDEzOSA0LjIxMjQ3Yy0uMjkyOTg2LjI3NDE5OTctLjQzOTQ3OS41OTczNjM1LS40Mzk0NzkuOTY5NDkxNiAwIC4yMzUwMjgyLjA2MDgzMi40NTE2OTQ5LjE4MjQ5NS42NS4xMjE2NjQuMTk4MzA1MS4yODQyOTYuMzU3NDM4OC40ODc4OTYuNDc3NDAxMXMuNDI5NTQ3LjE3OTk0MzUuNjc3ODQuMTc5OTQzNXoiIGZpbGw9IiNhMGEwYTAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zNjMgLTI2KSIvPjwvc3ZnPg==");

// Import bootstrap
@import "~bootstrap/scss/bootstrap";

// Colors

$color-primary: #B55C10;
$color-primary-dark: #723914;
$color-text-primary: #2F2F2F;
$color-text-secondary: #7F7F7F;
$color-text-tertiary: #9F9F9F;
$color-background-primary: #FFFFFF;
$color-background-secondary: #F2F2F2;
$color-background-item: #F8F8F8;
$color-placeholder: rgba($color: #000000, $alpha: 0.05);

// Fonts

@mixin font-body {
  font-size: $font-size-base;
  font-weight: 400;
  color: $body-color;
}

@mixin font-title-1 {
  font-size: 2.1rem;
  font-weight: 900;
  text-transform: uppercase;
  color: $color-primary-dark;
  @include media-breakpoint-up(sm) {
    font-size: 3.1rem;
  }
}

@mixin font-title-2 {
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  color: $color-text-secondary;
  @include media-breakpoint-up(sm) {
    font-size: 2.0rem;
  }
}

@mixin font-card-title {
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-text-primary;
}

@mixin font-card-body {
  font-size: 1.1rem;
  font-weight: 400;
  color: $color-text-primary;
}

@mixin font-note {
  font-size: 1.1rem;
  font-weight: 400;
  color: $color-text-primary;
}

@mixin font-list-placeholder {
  font-size: 1.6rem;
  font-weight: 500;
  color: $color-text-tertiary;
  @include media-breakpoint-up(sm) {
    font-size: 1.8rem;
  }
}

// Dimensions

$gutter: 20px;

// Utilities

@mixin animate-transition($properties: all, $duration: 0.2s, $timing-function: ease) {
  transition-property: $properties;
  transition-duration: $duration;
  transition-timing-function: $timing-function;
}

.home-intro .features {
  margin-top: 2rem;
}

.feature-card {
  display: flex;
  .image {
    flex-shrink: 0;
    width: 85px;
    background-size: cover;
    background-position: center;
    background-color: $color-placeholder;
  }
  .text {
    padding: 1rem;
    .title {
      @include font-card-title;
      margin-top: 0.3rem;
      margin-bottom: 0.8rem;
    }
    .content {
      @include font-card-body;
    }
  }
}

.news-card {
  .image {
    display: block;
    height: 150px;
    background-size: cover;
    background-position: center;
    background-color: $color-placeholder;
  }
  .text {
    padding: 1rem;
    .date {
      float: right;
      margin: -0.3rem 0.1rem 0.2rem 1rem;
      @include font-note;
      text-align: center;
      text-transform: uppercase;
      color: $color-text-secondary;
      .month {
        font-size: 0.8em;
        font-weight: 500;
        margin-top: -0.4em;
      }
    }
    .title {
      @include font-card-title;
      margin-top: 0.3rem;
      margin-bottom: 0.8rem;
    }
    .content {
      @include font-card-body;
    }
  }
  @include media-breakpoint-up(sm) {
    display: flex;
    .image {
      flex-shrink: 0;
      width: 150px;
      height: inherit;
    }
    .text {
      flex-grow: 1;
    }
  }
}

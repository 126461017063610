.team {

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .team-member {
      flex: 0 0 100%;
      margin: 1em;
      text-align: center;

      @include media-breakpoint-up(sm) {
        flex: 0 0 350px;
      }

      img {
        width: 100%;
      }

      h2 {
        font-size: 1.4rem;
        font-weight: 600;
        text-transform: uppercase;
        color: $color-text-primary;
      }

      h3 {
        font-size: 1.2rem;
        font-weight: 200;
        color: $color-text-secondary;
      }

      .details {
        margin: 1em;
      }
    }
  }
}

.furniture-steps {

  .furniture-step {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;

    img {
      width: 170px;
      height: 170px;
      flex: 0 0 auto;
    }

    .details {
      flex: 1 1 550px;
    }
  }
}




// OLD - TO REMOVE
.details-card {
  display: flex;
  .image {
    flex-shrink: 0;
    width: 170px;
    height: 170px;
    background-size: cover;
    background-position: center;
    margin: auto 0;
  }
  .text {
    padding: 1rem;
  }
}

.year {
  font-size: 2.4rem;
  font-weight: 700;
  color: $color-text-secondary;
}

.left-image {
  display: flex;
  .image {
    flex-shrink: 0;
    width: 300px;
    height: 200px;
    background-size: cover;
    background-position: center;
    margin: auto 0;
  }
  .text {
    padding: 1rem;
  }
}
